//https://material.angular.io/guide/theming
@use '@angular/material' as mat;

//include common styles for Angular Material - only ever include this mixin once!
@include mat.core();

//palettes for your theme using the Material Design palettes available in palette.scss (imported above)
//for each palette, you can optionally specify a default, lighter, and darker hue
//available color palettes: https://material.io/design/color/
$angular-primary: mat.define-palette(mat.$indigo-palette);
$angular-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

//warn palette is optional (defaults to red)
$angular-warn: mat.define-palette(mat.$red-palette);

//theme object - consists of configurations for individual theming systems such as "color" or "typography"
$angular-theme: mat.define-light-theme((
  color: (
    primary: $angular-primary,
    accent: $angular-accent,
    warn: $angular-warn,
  )
));

//theme styles for core components, alternatively can be imported and @include'd in each component
@include mat.all-component-themes($angular-theme);

//pics styles
@import './defaults.scss';

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'open sans', sans-serif;
}

app-root, html body {
    width: 100%;
}

.no-scroll {
    overflow-y: hidden;
}

.transparent {
    opacity: 0;
    transition: opacity 800ms ease-in-out;
}

.opaque {
    opacity: 1;
    transition: opacity 800ms ease-in-out;
}

mvp-nav {
    z-index: 50;
    position: fixed;
}

.container {
    position: relative;
    top: $navHeight;
}

.fixed-centre-postnav {
    position: fixed;
    top: 0;
    left: 50%;
    margin-left: calc($navHeight / -2);
    height: $navHeight;
    padding: 4px;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
